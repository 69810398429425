.tos-modal-heading {
    margin-right: 30px;
    @extend %typography-h2;
}

.tos-modal-content {
    @extend %typography-subtitle1;

    @include theme() {
        line-height: 22px;
    }

    li {
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
        @extend %anchor;
    }
}

.tos-modal-close {
    @extend %typography-h5;

    a {
        text-decoration: none;

        @include theme() {
            color: variable("primary-main");
        }
    }
}
