//
// Theme mixin
// --------------------------------------------------
// Theme module creator
//

@mixin export-theme($theme) {
  @each $theme-key, $theme-variable in $default-theme {
    :export {
      @each $key, $value in $theme-variable {
        @if type-of($key) == "number" {
          #{$theme-key}-#{$key}: $value;
        } @else {
          #{$theme-key}-#{unquote($key)}: $value;
        }
      }
    }
  }
}

@mixin theme($themes: $theme-variables) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function variable($key) {
  @return map-get($theme-map, $key);
}
