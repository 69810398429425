.ff-paid {
    &__container {
        margin-top: 10px;
        text-align: center;

        &__header {
            img {
                width: 130px;
                height: 56px;

                @include tablet-only() {
                    width: 112px;
                    height: 48px;
                }

                @include mobile() {
                    width: 112px;
                    height: 48px;
                }
            }
        }
    }

    &__payment-card-details {
        &__grid-container {
            &__main {
                width: 388px;
                margin: 0 auto;
                padding: 0 16px 80px 16px;

                @include mobile() {
                    width: auto;
                }

                &__price-bubble {
                    margin: 0 auto;
                    margin-top: 24px;
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include theme() {
                        background: variable("background-color-secondary");
                    }

                    &__container {
                        .just {
                            text-align: center;
                            @extend %typography-h3;
                            letter-spacing: 0.07px;
                            width: 90px;
                            margin: 0 auto;
                            padding-bottom: 4px;
                        }

                        .price-discount-container {
                            display: flex;
                            flex-direction: column-reverse;
                            position: relative;
                            width: min-content;
                            margin: 0 auto;

                            .discount-amount {
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                opacity: 0.5;
                                letter-spacing: -0.025em;

                                .currency {
                                    @extend %typography-doller;
                                    font-size: 14px !important;
                                    line-height: 28px !important;
                                }

                                .amount {
                                    @extend %typography-h1;
                                    font-size: 24px !important;
                                    line-height: 35px !important;
                                }

                                .decimalAmount {
                                    @extend %typography-subtitle3;
                                    font-size: 16px !important;
                                    line-height: 27px !important;
                                }
                            }

                            .line-through {
                                width: 100%;
                                border: 1px solid #000000;
                                height: 0;
                                opacity: 0.5;
                                position: absolute;
                                top: 16px;
                                transform: rotate(340deg);
                            }
                        }

                        .total-amount {
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;

                            .currency {
                                @extend %typography-doller;
                            }

                            .amount {
                                @extend %typography-h1;
                            }

                            .decimalAmount {
                                @extend %typography-subtitle3;
                            }
                        }
                    }
                }

                .decimal {
                    &__price-bubble {
                        &__US {
                            width: 169px;
                            height: 169px;
                        }

                        &__CA {
                            width: 176px;
                            height: 176px;
                        }
                    }
                }

                &__fetch-heading {
                    margin-top: 24px;
                    text-align: center;
                    @extend %typography-p;
                }

                &__fetch-heading-month-sub {
                    text-align: center;
                    @extend %typography-p;
                }

                &__heading {
                    margin-top: 24px;
                    margin-bottom: 16px;
                    @extend %typography-h2;

                    @include mobile() {
                        margin-bottom: 16px;
                    }
                }

                &__promoCode-error {
                    margin-top: 24px;
                    margin-bottom: 16px;

                    @include theme() {
                        background: variable("background-color-error");
                        border: 2px solid variable("error-main");
                    }

                    border-radius: 16px;
                    box-sizing: border-box;
                    padding: 16px;

                    p {
                        @extend %typography-p;
                        @include theme() {
                            color: variable("primary-main");
                            font-weight: 700;
                            font-size: 15px;
                        }

                        margin-top: 0;
                        margin-bottom: 0;

                        b {
                            display: block;
                        }
                    }
                }

                &__payment-error {
                    margin-bottom: 16px;

                    @include theme() {
                        background: variable("background-color-error");
                        border: 2px solid variable("error-main");
                    }

                    border-radius: 16px;
                    box-sizing: border-box;
                    padding: 16px;

                    p {
                        @extend %typography-p;
                        @include theme() {
                            color: variable("primary-main");
                            font-weight: 700;
                            font-size: 15px;
                        }

                        margin-top: 0;
                        margin-bottom: 0;

                        b {
                            display: block;
                        }
                    }
                }

                &__card-img {
                    margin-bottom: 14px;
                    display: flex;
                    grid-column-gap: 12px;
                    flex-flow: wrap;
                    grid-row-gap: 12px;

                    @include mobile() {
                        margin-bottom: 12px;
                    }

                    .opacity-Card {
                        opacity: 0.2;
                    }
                }

                &__card-details-group {
                    display: flex;
                    margin-top: 12px;
                    grid-column-gap: 12px;

                    @include mobile() {
                        margin-top: 12px;
                        display: flex;
                        grid-column-gap: 12px;
                    }

                    .expiration {
                        width: 188px;

                        @include mobile() {
                            width: 49%;
                            margin-bottom: 12px;
                        }
                    }

                    .cvc {
                        width: 188px;

                        @include mobile() {
                            width: 49%;
                            margin-bottom: 12px;
                        }
                    }
                }

                &__card-details {
                    .cardNumber {
                        margin-top: 12px;

                        @include mobile() {
                            margin-top: 12px;
                        }
                    }

                    .zipCode {
                        margin-top: 12px;

                        @include mobile() {
                            margin-top: 0px;
                        }
                    }

                    border-radius: 0px 32px;

                    .stripe-input-element {
                        .input-group {
                            &__wrapper {
                                border: 0;
                            }
                        }
                    }

                    .input-group {
                        &__wrapper {
                            @include theme() {
                                background-color: variable("background-color");
                            }

                            padding: 0px;

                            .input__control {
                                padding: 16px 7px 17px 16px;

                                &.StripeElement {
                                    height: 53px;

                                    &--focus {
                                        border: 2px solid #5757f2 !important;
                                        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
                                        border-radius: 16px;
                                    }

                                    &--empty {
                                        border: 2px solid #c9c7c7;
                                        border-radius: 16px;
                                    }

                                    &--invalid {
                                        border: 2px solid #e51e42;
                                        border-radius: 16px;

                                        .InputElement.is-invalid {
                                            color: #000;
                                        }
                                    }

                                    &--complete {
                                        border: 2px solid #c9c7c7;
                                        border-radius: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                &__secure-message {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;

                    .secured_message {
                        margin: 4px;
                        @extend %typography-subtitle2;
                    }
                }

                &__promo-code {
                    margin-top: 24px;

                    .apply-button-false,
                    .apply-button-undefined {
                        border-radius: 8px;
                        @extend %button-secondary;
                    }

                    .apply-button-true {
                        border-radius: 8px;
                        @extend %button-secondary;

                        @include theme() {
                            background-color: variable("error-main") !important;
                        }
                    }

                    &__failed {
                        margin-top: 2px;
                        margin-left: 12px;
                        @extend %input-text-error;
                    }

                    &__success {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 8px 16px;
                        border-radius: 8px;

                        @include theme() {
                            background: variable("background-color-secondary");
                        }

                        &__left {
                            span:first-child {
                                @extend %typography-p;
                                font-weight: 700 !important;
                                letter-spacing: -0.025em;
                                display: block;

                                @include theme() {
                                    color: variable("primary-main");
                                }
                            }

                            span:last-child {
                                @extend %typography-p;
                                letter-spacing: -0.025em;

                                @include theme() {
                                    color: variable("primary-main");
                                }
                            }
                        }

                        &__right {
                            .close-button {
                                img {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }
                }

                &__checkbox-accept {
                    margin-top: 24px;
                    display: flex;
                    align-items: center;
                    @extend %typography-subtitle2;

                    @include theme() {
                        line-height: 24px;
                    }

                    .accept-msg {
                        @include theme() {
                            color: variable("secondary-main");
                        }
                    }

                    .term-of-sales {
                        margin-left: 4px;

                        a {
                            text-decoration: none;
                            @extend %anchor;
                        }
                    }

                    .acknowledge-error {
                        .MuiIconButton-label {
                            span {
                                @include theme() {
                                    border-color: variable("error-main");
                                }
                            }
                        }
                    }
                }

                &__checkbox-accept-error {
                    @extend %typography-p;

                    @include theme() {
                        @extend %input-text-error;
                        margin-left: 26px;
                    }
                }

                &__privacy-policy {
                    margin-top: 24px;
                    margin-bottom: 42px;
                    text-align: center;

                    a {
                        text-decoration: none;
                        @extend %anchor;

                        @include mobile() {
                            @include theme() {
                                font-size: variable("font-size-xsmall");
                            }
                        }
                    }
                }
            }

            &__footer {
                &__button {
                    z-index: 9999;

                    @include theme() {
                        background: variable("background-color-secondary");
                    }

                    padding: 16px 0px;
                    position: fixed;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;

                    @include mobile() {
                        padding: 16px 16.5px;
                        width: 100%;
                        width: -moz-available;
                        width: -webkit-fill-available;
                    }

                    button {
                        @extend %button;
                        width: 342px;

                        @include mobile() {
                            width: 100%;
                        }

                        .arrow-icon {
                            margin-left: 8px;
                        }
                    }

                    .button-loading-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .submit-button-loading {
                        padding: 8px;

                        @include theme() {
                            background: #4dc4b1 !important;
                        }
                    }

                    .disabled-button {
                        @include theme() {
                            background: variable("primary-contrast-text");
                        }
                        &:active {
                            box-shadow: none;
                        }
                        &:hover {
                            box-shadow: none;
                        }
                    }
                }
            }

            &__acceptAndContinue {
                width: 331px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                bottom: 82px;
                left: 0;
                right: 0;
                position: absolute;
                margin: 0 auto;
                flex-direction: column;

                @include tablet-only() {
                    width: 356px;
                }

                @include mobile() {
                    width: 100%;
                }

                &__heading {
                    @include theme {
                        font-family: variable("secondary-font-family");
                        font-weight: variable("font-weight-extra-bold");
                        font-size: 32px;
                        line-height: 32px;
                    }
                }

                &__sub-heading {
                    margin-top: 16px;
                    text-align: center;
                    @extend %typography-p;

                    a {
                        text-decoration: none;
                        @extend %anchor;
                        font-size: 16px !important;
                        line-height: 22px !important;
                    }

                    @include mobile() {
                        padding: 0px 30px;
                    }
                }

                &__logo {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    bottom: 25px;
                    left: 0;
                    right: 0;

                    img {
                        width: 130px;
                        height: 56px;

                        @include tablet-only() {
                            width: 112px;
                            height: 48px;
                        }

                        @include mobile() {
                            width: 112px;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}
