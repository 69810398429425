@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");


@font-face {
    font-family: "Quincy cf";
    font-weight: 900;
    src: url("../../assets/fonts/Quincy-CF-Black.otf");
}