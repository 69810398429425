.component-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
}

.loader {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include tablet-only() {
        max-width: 550px;
    }

    @include mobile() {
        max-width: 100%;
    }

    .loader-lottie-top {
        margin-bottom: 20px;
        @extend %typography-h6;
        text-align: center;
    }

    .loader-lottie {
        width: 148px;
        height: 148px;

        @include mobile() {
            width: 124px;
            height: 124px;
        }
    }

    .loader-lottie-bottom {
        margin-top: 22px;
        @extend %typography-p;
        text-align: center;
    }
}

.no-click {
    pointer-events: none;
}

.lottie-img {
    .loader-lottie {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
