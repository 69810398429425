.input {
    $self: &;

    &-group {
        width: 100%;

        &__wrapper {
            position: relative;
            border: 2px solid #c9c7c7;
            background: #fff;
            min-height: 53px;
        }

        &.has-error {
            #{$self}-group__wrapper {
                border-color: #e51e42;
            }
        }

        &.has-focus {
            #{$self}-group__wrapper {
                box-shadow: 0px 6px 20px 0px #0000001a;
                border-color: #515151;
            }
        }

        &.has-focus,
        &.has-value {
            #{$self}__label {
                font-size: 12px;
                line-height: 16px;
                transform: translate(0, 4px);
                margin-top: 2px;
            }
        }
    }

    &_control,
    &_label {
        touch-action: manipulation;
    }

    &__control {
        box-sizing: border-box;
        background-color: transparent;
        width: 100%;
        min-height: 49px;
        border: none;
        padding: 22px 0 5px;
        font-weight: 400;
        font-family: "DM Sans";
        color: #000;
        outline: 0;
        overflow: hidden;
    }

    &__label {
        display: block;
        font-weight: 400;
        font-family: "DM Sans";
        color: #515151;
        position: absolute;
        transform-origin: top left;
        transform: translate(0, 18px);
        transition: all 0.2s ease-in-out;
        will-change: transform;
    }

    &__accessory {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
}

// Remove browser default styling
input {
    &:invalid,
    &:required:invalid {
        box-shadow: none;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }

    &[type="password"] {
        padding-right: 50px;
    }
}
