html {
    @include theme {
        font-size: variable("base-font-size");
    }
}

body {
    @include theme {
        color: variable("color-neutral-10");
    }
}
